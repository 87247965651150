var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('main', [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-row', {
    staticClass: "d-flex justify-content-center"
  }, _vm._l(_vm.cardDebt, function (value, label) {
    return _c('b-col', {
      key: label,
      staticClass: "m-1 m-md-0",
      attrs: {
        "sm": "12",
        "md": "4",
        "lg": "3",
        "xl": "2"
      }
    }, [_c('b-card', {
      staticStyle: {
        "min-height": "87%",
        "max-height": "150px",
        "position": "relative"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-wrap flex-col justify-content-center"
    }, [_c('h4', {
      staticClass: "text-center text-danger mb-2",
      staticStyle: {
        "overflow": "hidden"
      }
    }, [_c('strong', {
      staticClass: "d-block text-center"
    }, [_vm._v("Rp")]), _c('strong', [_vm._v(_vm._s(_vm.formatRupiah(value)))])]), _c('strong', {
      staticClass: "font-medium d-block text-center w-100 pb-1",
      staticStyle: {
        "position": "absolute",
        "left": "0",
        "bottom": "0"
      }
    }, [_vm._v(" " + _vm._s(label.toUpperCase()) + " ")])])])], 1);
  }), 1)], 1), _c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("Akun Dengan Kategori Hutang "), _vm.akuns.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.akuns.length) + ")")]) : _vm._e()]), _c('b-card', {
    staticClass: "mb-4"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.akuns,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(pajak)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.pajak) + "% ")];
      }
    }, {
      key: "cell(jenis)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.ket_jenis[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.ket_jenis[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(hutang)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" " + _vm._s(item.hutang ? 'Ya' : 'Tidak') + " ")])];
      }
    }, {
      key: "cell(saldo)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.saldo >= 0 ? _vm.formatRupiah(item.saldo) : "( " + _vm.formatRupiah(item.saldo * -1) + " )"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/akun/".concat(item.id, "?from=/hutang-perusahaan"));
            }
          }
        }, [_c('u', [_vm._v(_vm._s(item.nama))])])];
      }
    }, {
      key: "cell(nomor)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/akun/".concat(item.id, "?from=/hutang-perusahaan"));
            }
          }
        }, [_c('u', [_vm._v(_vm._s(item.nomor))])])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push("/akun/info/".concat(row.item.id, "?from=/hutang-perusahaan"));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('pembelian-belum-lunas')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }